import React from 'react'
import Scroll from 'react-scroll'
import styles from '../styles/components/scroll-element.module.scss'
import classNames from 'classnames/bind'
import leftArrowGray from '../images/left-arrow-dark.png'
import leftArrowWhite from '../images/left-arrow-white.png'
import rightArrowWhite from '../images/right-arrow-white.png'

let cx = classNames.bind(styles)

let ScrollLink = Scroll.Link

const ScrollElement = props => {
  let containerClass = cx('scrollDown', { white: props.color })
  let imageURL = props.color === 'white' ? leftArrowWhite : leftArrowGray
  let windowHeight = 0
  if (typeof window !== 'undefined') {
    windowHeight = window.innerHeight
  }
  return (
    <div>
      {/* FIRST SCROLL DOWN ON HERO SCREEN CALCULATES WINDOW HEIGHT ONCE */}
      {props.next === 'Scroll Down' && (
        <ScrollLink
          to={'ScrollDown'}
          offset={windowHeight}
          duration={1200}
          smooth={true}
          style={{ cursor: 'pointer' }}
        >
          <div className={containerClass}>
            {props.next !== '' && <img src={imageURL} alt="arrow" />}
            <div className={styles.scrollText}>{props.next}</div>
          </div>
        </ScrollLink>
      )}
      {props.next !== 'Scroll Down' && (
        <div>
          {props.prev !== '' && (
            <ScrollLink
              to={props.prev}
              duration={1200}
              smooth={true}
              style={{ cursor: 'pointer' }}
            >
              <div className={styles.scrollUp}>
                <div className={styles.scrollTextUp}>{props.prev}</div>
                <img src={rightArrowWhite} alt="arrow" />
              </div>
            </ScrollLink>
          )}
          {props.next !== '' && (
            <ScrollLink
              to={props.next}
              duration={1200}
              smooth={true}
              style={{ cursor: 'pointer' }}
            >
              <div className={containerClass}>
                <img src={imageURL} alt="arrow" />
                <div className={styles.scrollText}>{props.next}</div>
              </div>
            </ScrollLink>
          )}
        </div>
      )}
    </div>
  )
}

export default ScrollElement
